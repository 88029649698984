/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-11-13 16:45:33
 * @LastEditors: silencc
 * @LastEditTime: 2020-11-13 17:50:44
 */

/**
     * ruleTypes: 规则类型
     * genRules： rule 生成函数
     * ctx： Vue实例
     */
import { genModels } from '@/plugins/widget/c-form/rules';

export var models2 = genModels(function (genRules, ctx) {return [
  {
    span: 10,
    kname: 'a',
    itemProps: {
      labelWidth: '0' },

    component: {
      name: 'text' } },


  {
    span: 10,
    kname: 'a',
    itemProps: {
      labelWidth: '0' },

    component: {
      name: 'el-input',
      props: {
        filterable: true,
        type: 'textarea',
        showWordLimit: true,
        maxlength: 100 } } },



  {
    span: 4,
    kname: 'b',
    itemProps: {
      labelWidth: '0' },

    component: {} }];});


export var models = genModels(function (genRules, ctx) {return [
  {
    span: 8,
    kname: 'a',
    itemProps: {
      label: '编号：' },

    component: {
      name: 'text' } },


  {
    span: 8,
    kname: 'a',
    itemProps: {
      label: '填报人：' },

    component: {
      name: 'text' } },


  {
    span: 8,
    kname: 'a',
    itemProps: {
      label: '完成时间：' },

    component: {} },

  {
    span: 8,
    kname: 'a',
    itemProps: {
      label: '工作项目：' },

    component: {} },

  {
    span: 8,
    kname: 'a',
    itemProps: {
      label: '业务类型：' },

    component: {} },

  {
    span: 8,
    kname: 'a',
    itemProps: {
      label: '标准工时：' },

    component: {} },

  {
    span: 8,
    kname: 'a',
    itemProps: {
      label: '频次：' },

    component: {} },

  {
    span: 8,
    kname: 'a',
    itemProps: {
      label: '单频次积分：' },

    component: {} },

  {
    span: 8,
    kname: 'a',
    itemProps: {
      label: '实际工时：' },

    component: {} },

  {
    span: 9,
    kname: 'a',
    itemProps: {
      label: '选择工作难度：' },

    component: {} },

  {
    span: 9,
    kname: 'a',
    itemProps: {
      label: '选择工作角色：' },

    component: {} },

  {
    span: 9,
    kname: 'a',
    itemProps: {
      label: '选择岗位能力：' },

    component: {} },

  {
    span: 9,
    kname: 'a',
    itemProps: {
      label: '选择工作时间：' },

    component: {} },

  {
    span: 24,
    kname: 'li',
    type: 'list',
    models: models2(ctx) }

  // {
  //   span: 9,
  //   kname: 'li',
  //   itemProps: {
  //     label: '工作质量评价：'
  //   },
  //   type: 'list',
  //   models: models2(ctx)
  //   // component: {
  //   //   type: 'list',
  //   //   span: 24,
  //   //   models: models2(ctx)
  //   // }
  // }
  ];});

// form 默认值
export var formData = function formData() {return {
    li: [{}, {}, {}, {}, {}, {}, {}, {}] };};